<template>
  <div class="portfolio-page">

    <!-- Basic info: -->
    <h1>Portfolio</h1>
    <hr/>

    <div class="card">
      <div class="card-body">
        <div class="card-body-info">
          <h5 class="card-title">React Calendar App</h5>
          <p class="card-text">Simple calendar app created with react. Displays basic weather information for each day.</p>
          <a href="https://github.com/chatopaniagua/calendar-app" class="btn btn-dark">Github</a> 
          <a href="https://apps.chatopaniagua.com/calendar-app" class="btn btn-dark">Demo</a>
        </div>
        <div class="card-body-picture">
          <img src="../assets/portfolio-react-calendar.png" />
        </div>
      </div>
    </div>
<!-- 
    <div class="card">
      <div class="card-body">
        <div class="card-body-info">
          <h5 class="card-title">YNAB Préstamos</h5>
          <p class="card-text">Set of tools and features that help you manage your YNAB account more efficiently.</p>
          <a href="https://github.com/chatopaniagua/ynab-prestamos" class="btn btn-dark">Github</a> 
          <a href="https://apps.chatopaniagua.com/ynab-prestamos" class="btn btn-dark">Demo</a>
        </div>
        <div class="card-body-picture">
          <img src="../assets/portfolio-ynab-prestamos.png" />
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="card-body-info">
          <h5 class="card-title">Quinielify</h5>
          <p class="card-text">Create your own sport pools and share them with your friends.</p>
          <a href="https://github.com/chatopaniagua/quinielify" class="btn btn-dark">Github</a> 
          <a href="https://quinielify.com" class="btn btn-dark">Demo</a>
        </div>
        <div class="card-body-picture">
          <img src="../assets/portfolio-quinielify.png" />
        </div>
      </div>
    </div> -->

    <hr/>
    <router-link to="/" class="btn btn-outline-dark">Go Back</router-link>

  </div>

</template>

<script>
export default {
  name: 'PortfolioView',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.portfolio-page {
  width: 680px;
  max-width: 100%;
  margin: 0 auto;
  padding: 5em 2em;
  text-align: center;

  .card {
    text-align: left;
    margin-bottom: 1em;

    .card-body {
      justify-content: space-between;

      a.btn:not(:first-of-type) {
        margin-left: 0.5em;
      }

      img {
        width: 200px;
        padding: 2px;
        border: 1px solid lightgray;
        margin-top: 1em;
      }
    }
  }
}

hr {
  margin: 1.75em 0;
}

@media screen and (min-width: 740px) {
  
.portfolio-page {

  .card {

    .card-body {
      display: flex;
    
      p {
        margin-bottom: 2em;
      }

      img {
        margin-top: 0;
        margin-left: 1em;
      }
    }
  }
}
}


</style>
